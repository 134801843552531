import { render, staticRenderFns } from "./minimal.vue?vue&type=template&id=2417af36&scoped=true&"
import script from "./minimal.vue?vue&type=script&lang=js&"
export * from "./minimal.vue?vue&type=script&lang=js&"
import style0 from "./minimal.vue?vue&type=style&index=0&id=2417af36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2417af36",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowPortals: require('/home/forge/dev.isoudvikling.com/releases/20241031122905/components/NowPortals.vue').default,NowEnvironmentBar: require('/home/forge/dev.isoudvikling.com/releases/20241031122905/components/NowEnvironmentBar.vue').default})
