import { render, staticRenderFns } from "./NowAccountSidebar.vue?vue&type=template&id=4e73c040&scoped=true&"
import script from "./NowAccountSidebar.vue?vue&type=script&lang=js&"
export * from "./NowAccountSidebar.vue?vue&type=script&lang=js&"
import style0 from "./NowAccountSidebar.vue?vue&type=style&index=0&id=4e73c040&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e73c040",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NowIcon: require('/home/forge/dev.isoudvikling.com/releases/20241031122905/components/NowIcon.vue').default,NowButton: require('/home/forge/dev.isoudvikling.com/releases/20241031122905/components/NowButton.vue').default,NowDialog: require('/home/forge/dev.isoudvikling.com/releases/20241031122905/components/NowDialog.vue').default})
