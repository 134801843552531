import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3916fd1e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _65ecc448 = () => interopDefault(import('../pages/action/index.vue' /* webpackChunkName: "pages/action/index" */))
const _43f261c6 = () => interopDefault(import('../pages/archive/index.vue' /* webpackChunkName: "pages/archive/index" */))
const _579ed7df = () => interopDefault(import('../pages/audit/index.vue' /* webpackChunkName: "pages/audit/index" */))
const _03031c85 = () => interopDefault(import('../pages/deviation/index.vue' /* webpackChunkName: "pages/deviation/index" */))
const _5270b87d = () => interopDefault(import('../pages/it/index.vue' /* webpackChunkName: "pages/it/index" */))
const _370691ed = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _7b6402d9 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _69a803b8 = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _72428e6d = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _75593476 = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _7c7cceb5 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _8f411474 = () => interopDefault(import('../pages/report/index.vue' /* webpackChunkName: "pages/report/index" */))
const _0950b761 = () => interopDefault(import('../pages/risk/index.vue' /* webpackChunkName: "pages/risk/index" */))
const _894af58c = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _baf9cb9e = () => interopDefault(import('../pages/styles.vue' /* webpackChunkName: "pages/styles" */))
const _19ef1316 = () => interopDefault(import('../pages/suggestion/index.vue' /* webpackChunkName: "pages/suggestion/index" */))
const _58f41697 = () => interopDefault(import('../pages/task/index.vue' /* webpackChunkName: "pages/task/index" */))
const _469307d6 = () => interopDefault(import('../pages/unit/index.vue' /* webpackChunkName: "pages/unit/index" */))
const _1117d26a = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _841df782 = () => interopDefault(import('../pages/account/organizations.vue' /* webpackChunkName: "pages/account/organizations" */))
const _43e7174c = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _5246f971 = () => interopDefault(import('../pages/account/security.vue' /* webpackChunkName: "pages/account/security" */))
const _55cabeaf = () => interopDefault(import('../pages/audit/plan/index.vue' /* webpackChunkName: "pages/audit/plan/index" */))
const _9b1c0fa4 = () => interopDefault(import('../pages/organization/billing.vue' /* webpackChunkName: "pages/organization/billing" */))
const _f4756664 = () => interopDefault(import('../pages/organization/companies.vue' /* webpackChunkName: "pages/organization/companies" */))
const _122ef194 = () => interopDefault(import('../pages/organization/events.vue' /* webpackChunkName: "pages/organization/events" */))
const _08adaa71 = () => interopDefault(import('../pages/organization/groups.vue' /* webpackChunkName: "pages/organization/groups" */))
const _639f85f0 = () => interopDefault(import('../pages/organization/job.vue' /* webpackChunkName: "pages/organization/job" */))
const _007c1fcc = () => interopDefault(import('../pages/organization/members.vue' /* webpackChunkName: "pages/organization/members" */))
const _b696f50c = () => interopDefault(import('../pages/organization/subscription.vue' /* webpackChunkName: "pages/organization/subscription" */))
const _4107a16b = () => interopDefault(import('../pages/password/activate/index.vue' /* webpackChunkName: "pages/password/activate/index" */))
const _334956db = () => interopDefault(import('../pages/password/forgot/index.vue' /* webpackChunkName: "pages/password/forgot/index" */))
const _b8eadca6 = () => interopDefault(import('../pages/password/reset/index.vue' /* webpackChunkName: "pages/password/reset/index" */))
const _6c092d72 = () => interopDefault(import('../pages/task/group/index.vue' /* webpackChunkName: "pages/task/group/index" */))
const _180172ad = () => interopDefault(import('../pages/unit/category/index.vue' /* webpackChunkName: "pages/unit/category/index" */))
const _568b099a = () => interopDefault(import('../pages/action/_id/index.vue' /* webpackChunkName: "pages/action/_id/index" */))
const _9d0a296c = () => interopDefault(import('../pages/audit/_id/index.vue' /* webpackChunkName: "pages/audit/_id/index" */))
const _5b7f2620 = () => interopDefault(import('../pages/deviation/_id/index.vue' /* webpackChunkName: "pages/deviation/_id/index" */))
const _390f4ce8 = () => interopDefault(import('../pages/it/_id/index.vue' /* webpackChunkName: "pages/it/_id/index" */))
const _635c3db1 = () => interopDefault(import('../pages/report/_id/index.vue' /* webpackChunkName: "pages/report/_id/index" */))
const _0e3cb301 = () => interopDefault(import('../pages/suggestion/_id/index.vue' /* webpackChunkName: "pages/suggestion/_id/index" */))
const _ab4f707e = () => interopDefault(import('../pages/unit/_id/index.vue' /* webpackChunkName: "pages/unit/_id/index" */))
const _3ea693f3 = () => interopDefault(import('../pages/report/_id/_procedure/a/_appendix/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/a/_appendix/index" */))
const _0c9fe037 = () => interopDefault(import('../pages/report/_id/_procedure/_content/index.vue' /* webpackChunkName: "pages/report/_id/_procedure/_content/index" */))
const _b99c805a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3916fd1e,
    name: "account"
  }, {
    path: "/action",
    component: _65ecc448,
    name: "action"
  }, {
    path: "/archive",
    component: _43f261c6,
    name: "archive"
  }, {
    path: "/audit",
    component: _579ed7df,
    name: "audit"
  }, {
    path: "/deviation",
    component: _03031c85,
    name: "deviation"
  }, {
    path: "/it",
    component: _5270b87d,
    name: "it"
  }, {
    path: "/login",
    component: _370691ed,
    name: "login"
  }, {
    path: "/logout",
    component: _7b6402d9,
    name: "logout"
  }, {
    path: "/offline",
    component: _69a803b8,
    name: "offline"
  }, {
    path: "/onboarding",
    component: _72428e6d,
    name: "onboarding"
  }, {
    path: "/organization",
    component: _75593476,
    name: "organization"
  }, {
    path: "/register",
    component: _7c7cceb5,
    name: "register"
  }, {
    path: "/report",
    component: _8f411474,
    name: "report"
  }, {
    path: "/risk",
    component: _0950b761,
    name: "risk"
  }, {
    path: "/search",
    component: _894af58c,
    name: "search"
  }, {
    path: "/styles",
    component: _baf9cb9e,
    name: "styles"
  }, {
    path: "/suggestion",
    component: _19ef1316,
    name: "suggestion"
  }, {
    path: "/task",
    component: _58f41697,
    name: "task"
  }, {
    path: "/unit",
    component: _469307d6,
    name: "unit"
  }, {
    path: "/verify",
    component: _1117d26a,
    name: "verify"
  }, {
    path: "/account/organizations",
    component: _841df782,
    name: "account-organizations"
  }, {
    path: "/account/password",
    component: _43e7174c,
    name: "account-password"
  }, {
    path: "/account/security",
    component: _5246f971,
    name: "account-security"
  }, {
    path: "/audit/plan",
    component: _55cabeaf,
    name: "audit-plan"
  }, {
    path: "/organization/billing",
    component: _9b1c0fa4,
    name: "organization-billing"
  }, {
    path: "/organization/companies",
    component: _f4756664,
    name: "organization-companies"
  }, {
    path: "/organization/events",
    component: _122ef194,
    name: "organization-events"
  }, {
    path: "/organization/groups",
    component: _08adaa71,
    name: "organization-groups"
  }, {
    path: "/organization/job",
    component: _639f85f0,
    name: "organization-job"
  }, {
    path: "/organization/members",
    component: _007c1fcc,
    name: "organization-members"
  }, {
    path: "/organization/subscription",
    component: _b696f50c,
    name: "organization-subscription"
  }, {
    path: "/password/activate",
    component: _4107a16b,
    name: "password-activate"
  }, {
    path: "/password/forgot",
    component: _334956db,
    name: "password-forgot"
  }, {
    path: "/password/reset",
    component: _b8eadca6,
    name: "password-reset"
  }, {
    path: "/task/group",
    component: _6c092d72,
    name: "task-group"
  }, {
    path: "/unit/category",
    component: _180172ad,
    name: "unit-category"
  }, {
    path: "/action/:id",
    component: _568b099a,
    name: "action-id"
  }, {
    path: "/audit/:id",
    component: _9d0a296c,
    name: "audit-id"
  }, {
    path: "/deviation/:id",
    component: _5b7f2620,
    name: "deviation-id"
  }, {
    path: "/it/:id",
    component: _390f4ce8,
    name: "it-id"
  }, {
    path: "/report/:id",
    component: _635c3db1,
    name: "report-id"
  }, {
    path: "/suggestion/:id",
    component: _0e3cb301,
    name: "suggestion-id"
  }, {
    path: "/unit/:id",
    component: _ab4f707e,
    name: "unit-id"
  }, {
    path: "/report/:id/:procedure/a/:appendix",
    component: _3ea693f3,
    name: "report-id-procedure-a-appendix"
  }, {
    path: "/report/:id/:procedure/:content",
    component: _0c9fe037,
    name: "report-id-procedure-content"
  }, {
    path: "/",
    component: _b99c805a,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
